Notification = null
### @ngInject ###
Notification = ->
    controller = null
    ### @ngInject ###
    controller = ($scope, $timeout, AlertService) ->
        vm = @

        active  = false
        promise = null

        ## - END PRIVATE - ##

        vm.data = AlertService.getData()
        $scope.$watch 'notification.data.message', (n) ->
            if not n and active is true and vm.autoClose is no
                vm.close()
                return

            return unless n

            active    = true
            vm.styles = ['show']

            if vm.data.success
                vm.icon = 'ion-checkmark'
                vm.styles.push 'success'
            else if vm.data.fail
                vm.icon = 'ion-alert-circled'
                vm.styles.push 'fail'
            else if vm.data.info
                vm.icon = 'ion-ios7-information-outline'
                vm.styles.push 'info'

            if AlertService.autoClose
                vm.autoClose = yes
                promise = $timeout ->
                    vm.close()
                , 5000
            else
                vm.autoClose           = no
                AlertService.autoClose = on

            vm.title    = if vm.data.title then vm.data.title else vm.data.message
            vm.subtitle = if vm.data.subtitle then vm.data.subtitle else ''
            vm.describe = if vm.data.title then vm.data.message else ''
            vm.showUp   = yes
            vm.styles.push 'long' if vm.subtitle and vm.describe
            return


        vm.icon      = ''
        vm.title     = ''
        vm.subtitle  = ''
        vm.describe  = '' #this is really a long text to descrive what happen to your app'
        vm.autoClose = yes
        vm.styles    = []

        vm.close = ->
            $timeout.cancel promise
            active    = false
            vm.styles = []
            AlertService.resetStatus()
            return
        return


    directive =
        restrict: 'EA'
        controllerAs: 'notification'
        controller: controller
        templateUrl: 'scripts/components/ui/notification.drt.tpl.cch.html'
    directive


angular.module 'app'
.directive 'glNotification', Notification
